<template>
  <div class="content">
    <breadcrumb
      :path="{'Lainaa': '/lainaa', 'Lainaa 50 000 euroa': ''}"
    />
    <div class="subpage_floating">
      <img :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)" :alt="this.$route.path.substring(1)">
      <sliders :luottoraja="form.luottoraja" :maksuaika="form.maksuaika" class="is-hidden-touch" />
    </div>
    <h1>Lainaa 50 000 euroa ilman vakuuksia - alkaen 395 €/kk</h1>
    <p>
      Harkitsetko 50 000 euron lainan ottamista? Me Rahalaitoksella löydämme sinulle parhaan lainatarjouksen. Kun haet lainaa sivujemme kautta, kilpailutamme lainan lähes 20 luotonmyöntäjän kesken, joista jokainen tekee sinulle tarjouksen. Voit valita parhaan tarjouksen ja näin säästää kuluissa.
    </p>
    <sliders :luottoraja="form.luottoraja" :maksuaika="form.maksuaika" class="is-hidden-desktop" />
    <h2>
      Miksi hakea lainaa Rahalaitos.fi palvelun kautta?
    </h2>
    <p>
      Tehtävämme on löytää sinulle edullisin laina! Sinun kannattaa käyttää palveluamme koska:
    </p>
    <ul>
      <li>säästät aikaa (täytät vain yhden hakemuksen),</li>
      <li>säästät selvää rahaa (valitset edullisimman tarjouksen),</li>
      <li>palvelu ei sido tai velvoita sinua mihinkään,</li>
      <li>palvelun käyttö ei maksa sinulle mitään.</li>
    </ul>
    <p>
      Olemme auttaneet jo tuhansia suomalaisia saamaan edullisempia lainatarjouksia.
    </p>
    <h3>
      Miten haen 50 000 euroa lainaa Rahalaitoksen kautta?
    </h3>
    <p>
      Lainan hakeminen tapahtuu netissä, halutessa voit olla yhteydessä asiakaspalveluumme ja täyttää hakemus puhelimitse. Valitse ensin haluamasi lainasumma sekä -aika ja täytä sen jälkeen omat tietosi. Lähetettyäsi lomakkeen, me toimitamme sen yhteistyökumppaneillemme jotka ovat pankkeja sekä muita rahoituslaitoksia.
    </p>
    <p>
      Pääset näkemään saamiasi lainatarjouksia reaaliaikaisesti palvelussamme ja voit rauhassa päättää minkä niistä valitset. Mikäli päätät hyväksyä jonkun saamistasi tarjouksista, tulet saamaan rahat tilille viimeistän 1-2 arkipäivän kuluessa (huom. tämä aika saattaa vaihdella eri pankkien välillä).
    </p>
    <h3>
      Millä ehdoilla voin lainata 50 000 euroa?
    </h3>
    <p>
      Jotta yhteistyökumppanimme voivat myöntää sinulle lainaa, tulee luottotietosi olla kunnossa ja sinulla tulee olla säännölliset eläke- tai palkkatulot. Saadaksesi lainaa tulee sinun täyttää ainakin alla olevat perusehdot:
    </p>
    <ul>
      <li>olet vähintään 20-vuotias,</li>
      <li>sinulla on yli 600 € kuukausitulot,</li>
      <li>sinulla ei ole julkisia maksuhäiriömerkintöjä.</li>
    </ul>
    <strong>
      Täytä siis hakemus heti (se on ilmaista eikä sido sinua mihinkään) ja löydä edullisin tarjous 50 000 euron lainalle!
    </strong>
  </div>
</template>

<script>
import sliders from '~/components/subpage/sliders'

export default {
  layout: 'subpage',
  name: 'Lainaa50000Euroa',
  components: {
    sliders,
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  data () {
    return {
      form: {
        luottoraja: 50000,
        maksuaika: 9
      }
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Lainaa 70 000 euroa',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/lainaa-60000-euroa'
      },
      {
        title: 'Vakuudetta',
        subtitle: 'Et tarvitse omaisuutta pantiksi',
        link: '/parhaat-lainatarjoukset'
      },
      {
        title: 'Tarvitsetko lainaa?',
        subtitle: 'Näin voimme auttaa sinua',
        link: '/lainaa'
      }
    ] )
  },
  head () {
    return {
      title: 'Lainaa 50 000 euroa ilman vakuuksia | alkaen 395 €/kk',
      meta: [
        { hid: 'description', name: 'description', content: 'Etsitkö 50 000 € lainaa? Kilpailuta eri lainapaikat ilmaiseksi meillä - voit saada jopa 25 lainatarjousta! Kokeile heti!' }
      ]
    }
  }
}
</script>
