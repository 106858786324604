<template>
  <div>
    <main-hero
      title="Kilpailuta moottoripyörälaina maksutta"
      subtitle="Yhdellä hakemuksella useita lainatarjouksia."
      side-title="Kilpailuta moottoripyörä-lainasi!"
      :side-boxes="sideBoxes"
      survey-title="Asiakasarviot"
      :breadcrumb="{'Lainaa': '/lainaa', 'Moottoripyörälaina': ''}"
    />
    <section class="hero has-background-rlgray">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-white is-size-4 has-text-centered">
            Moottoripyörälaina kannattaa kilpailuttaa.
          </h2>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <loan-steps />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <h2>
            Moottoripyörälaina - Kilpailuttamalla löydät sopivimman lainan unelmiesi kaksipyöräiseen
          </h2>
          <p>
            <i>
              Kukapa ei rakastaisi sitä vapauden tunnetta, jonka koet kiitäessäsi moottoripyörän selässä pitkin
              Suomen upeita teitä metsien sekä peltojen läpi aina järvien ja merien rantateille saakka. Tai kukapa
              siitä ei olisi ainakaan haaveillut. Mikäli sinäkin haluaisit tänä kesänä painaa kaasua ja nostaa
              kytkintä, auttaa Rahalaitos sinua toteuttamaan unelmasi kilpailuttamalla moottoripyörälainan puolestasi,
              jotta voit itse keskittyä moottoripyörän ostamiseen.
            </i>
          </p>
          <p>
            Aina kevään alkaessa tulevat mukana myös kaksipyöräiset kulkuvälineet, kuten moottoripyörät, polkupyörät
            ja mopot. Kaksipyöräisiä alkaa näkyä katukuvassa koko ajan enemmän, jolloin usealle meistä tulee hinku
            hankkia myös oma kulkupeli, kuten moottoripyörä. Moottoripyörää hankkiessa moni kuitenkin, yllättyy kuinka
            paljon moottoripyörät ja siihen vaadittava kortti, vakuutukset ja varusteet maksavat. Me annamme
            rahanarvoisia vinkkejä, miten sinä voit hankkia unelmiesi moottoripyörän edullisella kulutusluotolla ja
            mitä kaikkea kannattaa ottaa huomioon moottoripyörää hankittaessa.
          </p>
          <h3>
            Minkälaisen moottoripyörän hankin?
          </h3>
          <p>
            Siinä, missä auto hankitaan useimmiten käytännön tarpeisiin, on moottoripyörä Suomen olosuhteissa
            enemmänkin harrasteväline. Koska jokainen harrastaja on erilainen, löytyy myös pyöriä joka lähtöön.
            Piditpä sitten maastossa kurvailusta tai pitkistä road tripeista. Haluatpa sitten runsaasti tehoa tai
            nautiskella rauhallisesta menosta. Kaipaatpa sitten paljon säilytystilaa tai mahdollisesti jopa sivuvaunua.
            Moottoripyöriä löytyy joka lähtöön, joten pyörää valittaessa tarkoitus ratkaisee.
          </p>
          <p>
            Tarkoituksesta puhuttaessa on hyvä miettiä moottoripyörän kokoa. Tyypillinen ensimmäinen moottoripyörä on
            500-kuutioinen pyörä, joiden hinnat liikkuvat 3 000–8 000 euron luokassa. Hintaan vaikuttaa merkittävästi
            se, onko kyseessä uusi vai käytetty pyörä, sekä pyörän merkki. Suomessa tällä hetkellä myydyimpiä
            moottoripyörämerkkejä ovat Yamaha, BMW, Honda, KTM, Kawasaki sekä legendaarinen Harley-Davidson.
            Vaihtoehdot eivät toki näihin pääty, mutta merkkien yleisyys takaa esimerkiksi osaavat mekaanikot pyörien
            korjauksiin.
          </p>
          <p>
            Hintahaitari moottoripyörissä on laaja, joten kysymys siitä ostaako uusi vai käytetty, on relevantti.
            Tällöin kannattaa muistaa samat asiat, kun autojenkin suhteen - uusi moottoripyörä takaa runsaasti
            huolettomia kilometrejä, mutta myös pikaisen arvon alenemisesta. Käytetyssä vaihtoehdossa houkuttimena
            toimii taas edullinen hinta, mutta hyvän yksilön löytämisessä ostajalta vaaditaan työtä ja asiantuntemusta.
            Mikäli kyseessä on ensimmäisen moottoripyörän osto, on kaupoille kannattavaa ottaa mukaan pyöristä tietävä
            kaveri varmistamaan pyörän kunto.
          </p>
          <h3>
            Mikä on paras tapa rahoittaa moottoripyörä?
          </h3>
          <p>
            Paras moottoripyörälaina riippuu elämäntilanteestasi ja siitä, kuinka olet pitänyt huolta raha-asioistasi.
            Jos käytössäsi on vapaata vakuutta, vakuudellinen pankkilaina on koroltaan edullisin moottoripyörälaina.
            Mikäli haluat tehdä kaupat nopeasti, on vakuudeton moottoripyörälaina kätevin tapa rahoittaa moottoripyörä.
          </p>
          <p>
            Vakuudeton laina on lainamuoto, jonka voit käyttää haluamaasi tarkoitukseen. Voit siis samalla lainalla
            kattaa moottoripyörän lisäksi myös muut kulut kuten ajovarusteet, huollot sekä moottoripyörävakuutuksen.
            Vakuudettoman moottoripyörälainan hakuprosessi on nopea, etkä tarvitse omaisuutta kuten asuntoa tai
            arvopapereita lainan vakuudeksi. Lainan vakuutena toimii takaisinmaksukykysi.
          </p>
          <p>
            Moottoripyörälainaa miettiessä kannatta apuna hyödyntää lainalaskuriamme sopivan lainan löytämiseen.
            Lainalaskuri auttaa lainaerien, moottoripyörälainan koron ja maksuajan hahmottamisessa.
            <nuxt-link to="/lainalaskuri">
              Kokeile maksutonta lainalaskuriamme sivustollamme ja kartoita sinun talouteesi sopiva lainamäärä.
            </nuxt-link>
            Voit siirtyä lainahakemukselle suoraan laskurista.
          </p>
          <h3>
            Miksi ottaisin vakuudettoman lainan?
          </h3>
          <p>
            Vakuudettoman lainan saanti Rahalaitokselta on erittäin helppoa sekä nopeaa. Sillä on mahdollista
            rahoittaa useita eri hankintoja eri aikaisesti ja vapaasti omien tarpeidesi mukaisesti. Perinteisellä
            osamaksusopimuksella voit maksaa vain jonkun tietyn yksittäisen hankinnan kuten moottoripyörän ja olet
            sidottu käyttämään ostopaikkana vain osamaksun tarjoavaa yritystä. Rahalaitoksen moottoripyörälainalla
            voit maksaa niin moottoripyörän kuin muutkin tarpeesi.
          </p>
          <h3>
            Paljonko moottoripyörälainaa tarvitsen?
          </h3>
          <p>
            Kun moottoripyörälainaa haetaan, sorrutaan usein miettimään vain pyörän ostohintaa eikä huomioida
            mahdollisia tulevia kustannuksia. Lainan mitoittamisessa on tärkeä huomioida itse ajoneuvon ja polttoaineen
            lisäksi myös ajovarusteet, joihin kuuluvat kypärä, housut, takki, saappaat ja hanskat. Koska nämä varusteet
            ovat turvallisuuden kannalta hyvin tärkeitä, ei varusteiden laadussa kannata tinkiä. Hyviin varusteisiin
            voikin helposti upota tuhannen euroa.
          </p>
          <p>
            Pyörä on lisäksi syytä vakuuttaa. Vakuutusmaksut riippuvat pyörän tyypistä, iästä ja tehosta sekä
            kuljettajan iästä. Myös moottoripyörävakuutuksissa on suuria eroja ja nekin on syytä kilpailuttaa samaan
            tapaan kuin moottoripyörälaina.
          </p>
          <p>
            Lainasummassa on hyvä ottaa huomioon myös moottoripyörän mahdollisesti kaipaamat remontit ja
            lisävarustehankinnat. Kätevä harrastaja voi toki säästää pitkän sentin tekemällä remontit itse, mutta uudet
            rattaat, pyörät, ketjut, öljyt, suodattimet, kytkimet ja muut osat maksavat joka tapauksessa.
          </p>
          <p>
            Yllä mainittujen menoerien ynnäilyjen jälkeen on syytä siirtyä kilpailuttamaan omien lainatarpeiden
            kokoinen lainasumma. Edullisen korkotason lainallesi löydät Rahalaitoksen palvelusta, joka kilpailuttaa
            yhdellä hakemuksella moottoripyörälainasi yli 20 pankilla ja luotonmyöntäjällä.
            <nuxt-link to="/lainaa">Siirry hakemukseen tästä.</nuxt-link>
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <faq />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <subpages />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <banks />
        </lazy-hydrate>
      </div>
    </section>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'MoottoripyoraLaina',
  components: {
    LazyHydrate,
    banks: () => import( '~/components/layout/banks' ),
    faq: () => import( '~/components/layout/pages/moottoripyoralainaFaq' ),
    loanSteps: () => import( '~/components/layout/loanSteps' ),
    mainHero: () => import( '~/components/layout/mainHero' ),
    subpages: () => import( '~/components/layout/subpages' )
  },
  data () {
    return {
      sideBoxes: [
        {
          icon: () => import( '~/assets/img/icons/banks.svg?inline' ),
          title: 'Lainatarjous jopa',
          subtitle: '25 pankilta'
        },
        {
          icon: () => import( '~/assets/img/icons/time.svg?inline' ),
          title: 'Helposti ja',
          subtitle: 'nopeasti'
        },
        {
          icon: () => import( '~/assets/img/icons/money.svg?inline' ),
          title: 'Lainaa jopa',
          subtitle: '70 000 €'
        }
      ]
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Lainaa ilman takaajia',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/lainaa-ilman-takaajia'
      },
      {
        title: 'Lainaa 30 000 euroa',
        subtitle: 'Suuriin ja pieniin unelmiin',
        link: '/lainaa-30000-euroa'
      },
      {
        title: 'Yhdistä lainasi',
        subtitle: 'Selkiytä talouttasi ja säästä kuluissa',
        link: '/yhdista-lainat'
      }
    ] )
  },
  head () {
    return {
      title: 'Moottoripyörälaina - Kilpailuta moottoripyörälaina | Rahalaitos',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Rahalaitoksen kautta voit saada jopa 70 000 € moottoripyörälainaa ilman vakuuksia.
            Täytä hakemus ilmaiseksi jo tänään!`
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi/moottoripyoralaina' }
      ]
    }
  }
}
</script>
