<template>
  <div class="content">
    <breadcrumb
      :path="{'Lainaa': '/lainaa', 'Lainaa 25 000 euroa': ''}"
    />
    <div class="subpage_floating">
      <img :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)" :alt="this.$route.path.substring(1)">
      <sliders :luottoraja="form.luottoraja" :maksuaika="form.maksuaika" class="is-hidden-touch" />
    </div>
    <h1>Lainaa 25 000 euroa alkaen 217 €/kk</h1>
    <p>
      Harkitsetko auton ostamista tai kenties kodin remontointia? Näissä tapauksissa voi 25000 euron laina tulla tarpeeseen! <strong>Me Rahalaitoksella autamme sinua löytämään edullisimman lainan!</strong> Täytät vain yhden lainahakemuksen, niin lähetämme sen yli 15 yhteistyökumppanille, jotka tekevät sinulle tarjouksen 25000 euron lainasta.
    </p>
    <sliders :luottoraja="form.luottoraja" :maksuaika="form.maksuaika" class="is-hidden-desktop" />
    <h2>
      Voit saada 25000 € lainaa ilman vakuuksia
    </h2>
    <p>
      Kaikki yhteistyökumppaneidemme myöntämät <nuxt-link to="/lainaa-ilman-vakuuksia">lainat ovat vakuudettomia</nuxt-link>, eli sinun ei tarvitse asettaa taloa, asuntoa tai muuta varallisuutta vakuudeksi lainalle. Meidän kautta on mahdollista hakea <strong>lainaa ilman vakuuksia</strong> aina <nuxt-link to="/lainaa-50000-euroa">50 000 euroon</nuxt-link> asti.
    </p>
    <h3>
      Hakemuksen täyttäminen on ilmaista
    </h3>
    <p>
      Hakemuksen täyttäminen vie vain muutaman minuutin! Lisäksi, palvelumme käyttö on sinulle <strong>täysin ilmaista</strong> eikä hakemuksen täyttäminen <strong>sido sinua mihinkään</strong>. Täytettyäsi hakemuksen voit rauhassa verrata saamiasi lainatarjouksia ja sen jälkeen päättää nostatko lainan tilillesi vai et.
    </p>
    <h3>
      Miten pian voin saada lainan tilille?
    </h3>
    <p>
      Täytettyäsi hakemuksen, saat yleensä <strong>luottopäätöksen heti</strong> ja näet lainatarjoukset palvelussamme reaaliaikaisesti. Mikäli päätät hyväksyä jonkin tarjouksista, saat joissain tapauksissa <strong>rahat tilillesi jo samana päivänä</strong> - usein kuitenkin 1-2 arkipäivän kuluessa.
    </p>
    <h3>
      Ehdot 25 000 euron lainalle
    </h3>
    <p>
      Yhteistyökumppanimme harjoittavat vastuullista luotonantoa, joten taloutesi tulee olla kunnossa jotta voit saada lainaa. Jotta saisit myönteisen luottopäätöksen, tulee sinun täyttää seuraavat ehdot:
    </p>
    <ul>
      <li>
        kuukausitulosi ovat vähintään 600 €,
      </li>
      <li>
        olet vähintään 20-vuotias, ja
      </li>
      <li>
        sinulla ei ole julkisia maksuhäiriömerkintöjä.
      </li>
    </ul>
    <p>
      Muista että voit aina täyttää hakemuksen vaikka olisitkin epävarma - se ei maksa mitään ja me autamme sinua mielellämme!
    </p>
  </div>
</template>

<script>
import sliders from '~/components/subpage/sliders'

export default {
  layout: 'subpage',
  name: 'Lainaa25000Euroa',
  components: {
    sliders,
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  data () {
    return {
      form: {
        luottoraja: 25000,
        maksuaika: 8
      }
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Lainaa 30 000 euroa',
        subtitle: 'Suuriin ja pieniin unelmiin',
        link: '/lainaa-30000-euroa'
      },
      {
        title: 'Yhdistä lainasi',
        subtitle: 'Selkiytä talouttasi ja säästä kuluissa',
        link: '/yhdista-lainat'
      },
      {
        title: 'Autolaina',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/autolaina'
      }
    ] )
  },
  head () {
    return {
      title: 'Lainaa 25000 euroa ilman vakuuksia | Alkaen 217 €/kk',
      meta: [
        { hid: 'description', name: 'description', content: 'Etsitkö 25000 € lainaa? Kilpailuta eri lainapaikat ilmaiseksi meillä - voit saada jopa 25 lainatarjousta! Kokeile heti!' }
      ]
    }
  }
}
</script>
