<script>
import faq from '~/components/layout/faq'

export default {
  name: 'JoustoluottoFAQAccordion',
  extends: faq,
  data () {
    return {
      questions: [
        {
          title: 'Mikä on joustoluotto?',
          content: 'Joustoluotto on vakuudeton laina. Se muistuttaa ominaisuuksiltaan luottokorttia, jolloin voit nostaa lisää lainaa tai maksaa pois joustavasti tilanteesi mukaan.'
        },
        {
          title: 'Millainen joustoluotto kannattaa ottaa?',
          content: 'Joustoluoton valinnassa kannattaa kiinnittää huomiota kaikkiin lainan ehtoihin. Korko on helpoimmin vertailtava tunnusluku lainassa, mutta myös lainan muut kulut pitää huomioida. Joustoluottoa otettaessa kannattaa arvioida luottolimiitin kokonaissumma sen mukaan, että se on sopiva myös tulevia hankintoja mietittäessä. Koko lainalimiittiähän ei tarvitse ottaa käyttöön heti ja korot ja kulut maksetaan myös vain nostetun lainan mukaan.'
        },
        {
          title: 'Miten joustoluotto toimii?',
          content: 'Joustoluotto on käytettävissä aina tarpeen vaatiessa. Joustoluoton ottaessasi saat käyttöösi luottorajan, jonka puitteissa voit nostaa rahaa käyttöösi tarpeen mukaan. Joustoluottoa lyhennetään kuukausittain vähintään sovitun minimilyhennyksen verran ja sinulla on koko ajan tiedossasi sopimuksen tarjoama käytettävissä oleva taloudellinen jousto.'
        },
        {
          title: 'Miten löydän edullisimman joustoluoton?',
          content: 'Joustoluottoon saatavat ehdot ja sen korko sekä luottorajan määrä riippuvat hakijan tai hakijoiden taloustilanteesta. Kannattaa muistaa myös lainan kulujen vaikutus kustannuksiin, jonka takia kannattaa tarkistaa lainan kaikki kustannukset kertova todellinen vuosikorko. Rahalaitoksen kautta voit kilpailuttaa luotonantajat helposti yhdellä hakemuksella. Saat lainatarjoukset vertailtavaksi yhdellä kertaa Omarahalaitos -palveluun, jossa näet mitkä tarjouksista ovat merkitty limiittilainoiksi. Näitä voit vertailla helposti palvelussamme ja valita tarpeisiisi sopivimman.'
        },
        {
          title: 'Kuka voi hakea joustoluottoa?',
          content: 'Perusvaatimukset joustoluoton saamiseen ovat säännölliset eläke– tai palkkatulot sekä puhtaat luottotiedot. Iältään lainanhakijan tulee olla vähintään 20-vuotias.'
        },
        {
          title: 'Kuinka nopeasti saan joustoluoton käyttööni?',
          content: 'Saat yleensä päätöksen joustoluoton myöntämisestä heti hakemuksen jätettyäsi. Sopimuksen tehtyäsi rahat siirretään tilillesi jopa samana päivänä.'
        }
      ]
    }
  }
}
</script>
