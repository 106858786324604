<template>
  <div class="content">
    <breadcrumb
      :path="{'Luotto': '/luotto', 'Joustoluotto': ''}"
    />
    <div class="subpage_floating">
      <img
        :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)"
        :alt="this.$route.path.substring(1)"
      >
      <sliders class="is-hidden-touch" />
    </div>
    <h1 class="title">
      Joustoluotto antaa liikkumavaraa talouteen
    </h1>
    <p>
      Joustoluotto on hyvä vaihtoehto, kun rahaa tarvitaan aika ajoin erilaisiin hankintoihin, joiden maksamiseen ei juuri sillä hetkellä ole rahaa tilillä. Joustoluotto muistuttaa ominaisuuksiltaan luottokorttia.
    </p>
    <p>
      Joustoluotossa sinulla on käytössäsi sovittu luottoraja tai luottolimiitti, jonka puitteissa voit nostaa rahaa käyttöösi tarpeen mukaan.
    </p>
    <h2>
      Mikä on Joustoluotto?
    </h2>
    <p>
      On mahdollista sekoittaa joustoluotto helposti tavalliseen luottoon ellei siitä tiedä tarkemmin. Joustoluoton tarkoituksena on tarjota sinulle samoja ominaisuuksia kuin luottokortti, mutta kyseessä on laina.
    </p>
    <p>
      Erona luottokorttiin on siis se, että luotto koostuu vakuudettomana lainana otetusta luotosta, jota voi käyttää luottorajaan saakka. Voit siis nostaa myönnetyn luottorajan puitteissa joustavasti rahaa käyttöösi eli koko lainasumma ei siis tule yhtenä könttäsummana tilille.
    </p>
    <h2>
      Valitse joustoluotto, kun haluat lisäjoustoa hankintoihin
    </h2>
    <p>
      Joustoluottoon ei tarvita vakuuksia tai takaajia ja sitä tarjoavat useat pankit ja rahoituslaitokset. Kun kilpailutat lainasi Rahalaitoksen kautta, saat usein tarjouksia sekä kertalainoista että joustoluotoista, joita kutsutaan usein myös limiittiluotoiksi. Tarjouksiin on merkitty lainasumman alle, onko kyse kertaluotosta vai limiittiluotosta. Näin tiedät voitko käyttää samaa lainaa myöhemminkin joustavasti, kun tarvitset rahaa hankintoihin.
    </p>
    <h2>
      Joustoluotto on myös muilta ominaisuuksiltaan joustava
    </h2>
    <p>
      Eri pankkien ja rahoitusyhtiöiden joustoluotot eroavat ominaisuuksiltaan toisistaan, mutta yhteistä niille on se, että usein niissä on enemmän joustoa myös lainan ehdoissa verrattuna muihin kulutusluottoihin. Joustoluotolle voi usein saada myös lyhennysvapaita kuukausia, jolloin lainasta maksetaan vain korot ja mahdolliset tilinhoitomaksut. Lyhennysvapaiden kuukausien avulla voi tasapainottaa talouden menoja ilman että lainoja tarvitsee neuvotella uudelleen.
    </p>
    <p>
      Joustoluoton lyhennys on myös suunniteltu joustavaksi. Lainasopimuksessa määritellään minimilyhennys, mutta muuten voit itse määritellä kuinka paljon luottoa lyhennät ja kuinka pitkäksi laina-aika näin muodostuu. Lainasopimuksesta näet mitkä ovat joustoluoton minimilyhennykset ja muut ehdot.
    </p>
    <h2>
      Mikä on joustoluoton korko?
    </h2>
    <p>
      Joustoluoton korko riippuu aina luoton myöntävästä tahosta. Esimerkiksi Rahalaitoksen kautta haettujen ja saatujen lainatarjousten kanssa on aina esitelty jokaisen pankin ja luotonmyöntäjän joustoluoton korko sekä muut kustannukset.
    </p>
    <p>
      Edullisin joustoluotto löytyy siis vain vertaamalla. Koska jokainen pankki arvioi jokaisen luotonmyöntäjän hänen tulojen ja maksukyvyn mukaan, emme voi esittää yleismaallisia korkoprosentteja tai kertoa mistä edullisin joustoluotto mahdollisesti juuri sinulle löytyy.
    </p>
    <h2>
      Joustoluottoa voi hakea myös yhdessä
    </h2>
    <p>
      Henkilökohtaisiin menoihin lainaa haetaan useimmiten yksin, mutta pariskunnilla tulot ja menot ovat usein osittain yhteisiä. Lomamatkat tai yhteiseen asuntoon ostettavat kodinkoneet ja huonekalut ovat hyviä esimerkkejä hankinnoista, joissa kulut laitetaan usein puoliksi ja joiden hankinnassa joustoluottoa usein käytetään.
    </p>
    <p>
      Jos hankintoihin tarvitaan lainarahaa, kannattaa miettiä, olisiko lainaksi järkevintä hakea joustoluottoa yhdessä puolison kanssa. Useimmiten lainan hakeminen yhteishakijan kanssa on fiksua, koska tällöin laina järjestyy paremmilla ehdoilla.
    </p>
    <p>
      Lainanmyöntäjän näkökulmasta tilanne on aina parempi, jos lainaa pystytään maksamaan takaisin kahden henkilön toimesta. Rahalaitoksen kautta sinun on mahdollista hakea joustava <nuxt-link to="/kulutusluotto">kulutusluotto</nuxt-link> yksin tai yhdessä toisen hakijan kanssa.
    </p>
    <br>
    <div class="container">
      <faq />
    </div>
    <br>
    <h2>
      Täytä joustoluottohakemus nyt ja selvitä oman kulutusluottosi hinta jo tänään
    </h2>
    <p>
      Mikäli sinulla on tarve saadat joustoluotto heti tai ainakin nähdä minkälaisia joustoluoton korkoja sinulle on tarjolla, täytä joustoluottohakemus palvelussamme nyt ja selvitä oman joustoluottosi hinta jo tänään.
    </p>
    <p>
      Hae joustoluottoa helposti täytettävällä hakemuksellamme, jonka täyttäminen verkkosivuillamme vie aikaa muutaman minuutin, jonka jälkeen saat lainapäätöksen ja alat heti vastaanottamaan tarjouksia eri pankkien ja rahoitusyhtiöiden kulutusluotoista.
    </p>
    <p>
      Täyttämällä hakemuksen voit löytää sinulle sopivan joustoluoton vielä tänään.
    </p>
    <p>
      Jos sinulla on mitä tahansa kysyttävää lainanhakuun tai palveluumme liittyen, saat asiakaspalvelumme kiinni niin puhelimen, sähköpostin kuin yhteydenottolomakkeen avulla. Palvelemme puhelimitse numerossa 09 2311 3670 maanantaista perjantaihin kello 8.00-16.00. Sähköpostilla tavoitat meidät osoitteesta asiakaspalvelu@rahalaitos.fi.
    </p>
    <p>
      <b>Lue lisää aiheesta:</b>
    </p>
    <p>
      <nuxt-link to="/luottotili">Luottotili.</nuxt-link>
    </p>
    <p>
      <nuxt-link to="/luottokortti">Luottokortti.</nuxt-link>
    </p>
    <p>
      <nuxt-link to="/talous/credit-vai-debit">Credit vai debit.</nuxt-link>
    </p>
  </div>
</template>

<script>
import sliders from '~/components/subpage/sliders'
import faq from '~/components/layout/pages/joustoluottoFaq'

export default {
  layout: 'subpage',
  name: 'Joustoluotto',
  components: {
    sliders,
    faq,
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  async fetch ({ store }) {
    await store.dispatch('subpage/setPages', [
      {
        title: 'Tarvitsetko Lainaa?',
        subtitle: 'Näin voimme auttaa sinua',
        link: '/lainaa'
      },
      {
        title: 'Autolaina',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/autolaina'
      },
      {
        title: 'Lainaa 60 000 euroa',
        subtitle: 'Suuriin unelmiin',
        link: '/lainaa-60000-euroa'
      }
    ])
  },
  head () {
    return {
      title: 'Joustoluotto | Rahalaitos',
      meta: [
        { hid: 'description', name: 'description', content: 'Joustoluotto on hyvä vaihtoehto, kun rahaa tarvitaan aika ajoin erilaisiin hankintoihin, joiden maksamiseen ei juuri sillä hetkellä ole rahaa tilillä.' }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi/joustoluotto' }
      ]
    }
  }
}
</script>
